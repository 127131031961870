import React, { useEffect, useState } from "react";
import {
	Input,
	Button,
	message,
	Alert,
	notification,
	Upload,
	Checkbox,
	Modal,
	List,
	Select,
} from "antd";
import {
	LinkOutlined,
	UploadOutlined,
	CloudUploadOutlined,
	CloseOutlined,
	FileWordOutlined,
} from "@ant-design/icons";
import Events from "./../lib/events";
import IO from "./../lib/socketio";
import axios from "axios";

const InputUrl = (props: any) => {
	const [input, setInput] = useState("");
	const [fileList, setFileList] = useState<any>([]); // To store the files before send to api
	const [totalFile, setTotalFile] = useState<number>(0);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [checkedList, setCheckedList] = useState<any>([]);
	const [selectedPublihser, setSelectedPublisher] = useState<any>(null);

	// notification config
	notification.config({
		placement: "bottomRight",
		bottom: 50,
		duration: 3,
	});

	const pasteUrl = async () => {
		console.log(navigator);
		const text = await navigator.clipboard.readText();
		setInput(text);
	};

	const handleChange = (e: any) => {
		setInput(e.target.value);
	};

	const isUrl = (input: any) => {
		var url_pattern = false;
		var pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		);
		if (pattern.test(input)) {
			return true;
		} else {
			return false;
		}
	};

	const checkData = (input: any) => {
		var found = false;

		// props.fulldata.map((data: any) => {
		// 	// remove trailing slash from data.url and input
		// 	input = input.replace(/\/$/, "");
		// 	data.url = data.url.replace(/\/$/, "");
		// 	if (data.url === input) {
		// 		console.log(data.url);
		// 		found = true;
		// 	}
		// });
		return found;
	};

	const submitUrl = () => {
		if (input === "") {
			// message.warning("Cannot submit empty field!");
			notification.destroy();
			notification.warning({
				message: "Cannot submit empty field!",
				description: "Please enter URL.",
			});
		} else if (isUrl(input) === false) {
			// message.warning("Please enter URL!");
			notification.destroy();
			notification.warning({
				message: "Invalid input!",
				description: "Please enter proper URL.",
			});
		} else if (checkData(input) === true) {
			// message.warning("Article already exist. Please use other url!");
			notification.destroy();
			notification.warning({
				message: "Data already exist!",
				description: "Article already exist. Please use other url!",
			});
		} else {
			// message.info("URL has been submitted.");
			notification.destroy();
			notification.info({
				message: "URL submitted.",
				description: `${input} is being processed.`,
			});
			// Events.emit("submit", { status: "clicked" });
			IO.emit("send-url", {
				userId: sessionStorage.getItem("email"),
				url: input,
			});
		}
	};

	const handleFileChange = (info: any) => {
		const isDocFile =
			info.file.type === "application/msword" ||
			info.file.type ===
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
			info.file.type === "text/html";

		if (!isDocFile) {
			notification.error({
				message: "Wrong format file!",
				description: "You can only upload MS Word or HTML files!",
			});
		} else {
			// Check if a new file is in the fileList
			const newFile = fileList.find(
				(item: any) => item.name === info.file.name
			);
			if (newFile) {
				notification.info({
					message: "File already in the folder!",
					description: `${newFile.name} is exist in the file list`,
				});
			} else {
				setFileList((previous: any) => [...previous, info.file]);
				setCheckedList((previous: any) => [...previous, info.file]);
				setTotalFile(info.fileList.length);
			}
		}
	};

	const handleFileSubmit = (data: any) => {
		const uploadFiles = async () => {
			let error_upload_files: any = [];
			let successful_upload_files: any[] = [];
			let files_detail: any[] = [];

			const formData = new FormData();
			for (let i = 0; i < data.length; i++) {
				formData.append("files", data[i]);
			}
			console.log("Test10= ", formData);
			await axios
				.post(`/api/submit_file/upload_story_files/`, formData)
				.then((response) => {
					// Files upload successful
					const uploadedFiles: any = response.data.data;
					successful_upload_files.push(uploadedFiles["name"]);
					files_detail.push(uploadedFiles);
					console.log("Files saved to folder");
				})
				.catch((error) => {
					// File upload failed
					console.error("Failed to save the files:", error);
					console.log("No error= ", successful_upload_files);

					if (
						successful_upload_files != undefined &&
						successful_upload_files != null &&
						successful_upload_files.length != 0
					) {
						error_upload_files = fileList.filter(
							(item: any) => successful_upload_files.includes(item) === false
						);
					} else {
						console.log(fileList);
						error_upload_files = fileList.map((item: any) => {
							return item.name;
						});
					}
					setFileList([]);
					setCheckedList([]);
					setTotalFile(0);
				});

			// Display successfuly uploaded files
			if (
				successful_upload_files != undefined &&
				successful_upload_files != null &&
				successful_upload_files.length != 0
			) {
				setFileList([]);
				setCheckedList([]);
				setTotalFile(0);
				// removeFileState = false;
				notification.info({
					message: "File(s) processing!",
					description: "File(s) ready to scrape. Please wait for a while",
				});
				IO.emit("send-document", {
					userId: sessionStorage.getItem("email"),
					publisher: selectedPublihser,
					files_detail: files_detail,
				});
			}
			// Display error upload files
			if (
				error_upload_files != undefined &&
				error_upload_files != null &&
				error_upload_files.length != 0
			) {
				setFileList([]);
				setCheckedList([]);
				setTotalFile(0);
				notification.error({
					message: "Fail to save the file!",
					description: (
						<List
							style={{
								maxHeight: "400px",
								overflowY: "auto",
								overflowX: "auto",
							}}
							dataSource={error_upload_files}
							renderItem={(item, index) => (
								<List.Item>{`${item} upload failed`}</List.Item>
							)}
						/>
					),
				});
			}
		};

		if (selectedPublihser === null) {
			notification.error({
				message: "Cannot upload!",
				description: "Please select the publisher before upload",
			});
			handleModalClose();
		} else if (totalFile > 10 && data.length > 10) {
			notification.error({
				message: "Cannot upload!",
				description:
					"Maximum number files can being process per time is 10 files",
			});
			handleModalClose();
		} else {
			uploadFiles();
		}
		setModalOpen(false);
	};

	// Handle uploaded files are more than 5 or else
	useEffect(() => {
		if (fileList.length === totalFile && totalFile != 0) {
			if (totalFile > 5) {
				setModalOpen(true);
			} else {
				handleFileSubmit(fileList);
			}
		}
	}, [fileList]);

	// Handle checkbox list
	const handleCheckboxChange = (checkedValues: any) => {
		setCheckedList(checkedValues);
	};

	// Handle modal close without uplaod
	const handleModalClose = () => {
		setModalOpen(false);
		setFileList([]);
		setCheckedList([]);
		setTotalFile(0);
	};

	return (
		<div className="submit-story-container">
			<h1 style={{ fontWeight: "bold", lineHeight: 1.5715 }}>Submit Story</h1>

			<div style={{ display: "inline-block", marginBottom: "1.5%" }}>
				<h4
					style={{
						lineHeight: 1.5715,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<span style={{ fontWeight: "bold", marginRight: "3%" }}>
						Publisher
					</span>
					<Select
						showSearch
						style={{ borderRadius: "10px", width: "100%" }}
						placeholder="Select publisher ..."
						filterOption={(input, option) =>
							(option?.label ?? "").includes(input)
						}
						filterSort={(optionA: any, optionB: any) =>
							(optionA?.label ?? "")
								.toLowerCase()
								.localeCompare((optionB?.label ?? "").toLowerCase())
						}
						onChange={(selectedValue: any, data: any) =>
							setSelectedPublisher(data.label)
						}
						options={[
							{
								value: "Naracoorte Community News",
								label: "Naracoorte Community News",
							},
							{
								value: "Lord Howe Island Signal",
								label: "Lord Howe Island Signal",
							},
							{
								value: "Clarence Valley Independent",
								label: "Clarence Valley Independent",
							},
						]}
					/>
				</h4>

				<Upload
					fileList={fileList}
					showUploadList={false}
					name="file"
					accept=".doc,.docx,.html,.htm"
					multiple={true}
					beforeUpload={() => false}
					onChange={handleFileChange}
					onDrop={handleFileChange}
					// onRemove={handleFileRemove}
				>
					<div
						style={{
							border: "1px dashed black",
							width: "35vw",
							padding: "5vh 5vw 5vh 5vw",
							borderRadius: "5px",
							backgroundColor: "white",
						}}
					>
						<span className="paragraph-wrapper">
							<p>
								<CloudUploadOutlined
									style={{ fontSize: "200%", margin: "0px 0.5vw 0px 0.5vw" }}
								/>
							</p>
							<p>Drop files here or</p>
							<p>
								<Button className="browse-file-button">Browse File</Button>
							</p>
							<p>to upload</p>
						</span>
						<p style={{ color: "grey", marginTop: "2%" }}>
							Accepted File Types MS Word or HTML only
						</p>
					</div>
				</Upload>
			</div>
			{/* File list to upload modal */}
			{modalOpen == true ? (
				<Modal
					open={modalOpen}
					title={
						<div style={{ fontWeight: "bold" }}>
							<FileWordOutlined style={{ marginRight: "10px" }} />
							File List To Upload
						</div>
					}
					closable={false}
					footer={
						<div style={{ marginTop: "1%" }}>
							<Button
								className="button-modal-cancel"
								onClick={handleModalClose}
							>
								Abandon
							</Button>
							<Button
								className="button-modal-save-false"
								onClick={() => handleFileSubmit(checkedList)}
							>
								Upload
							</Button>
						</div>
					}
				>
					<Checkbox.Group
						value={checkedList}
						onChange={handleCheckboxChange}
						style={{ width: "100%", display: "grid" }}
					>
						{fileList.map((data: any) => {
							return (
								<Checkbox
									key={data.uid}
									value={data}
									style={{
										marginLeft: "8px",
										marginTop: "1px",
										marginBottom: "1px",
									}}
								>
									{data.name}
								</Checkbox>
							);
						})}
					</Checkbox.Group>
				</Modal>
			) : null}

			<Input.Group compact>
				<Button className="link-button" onClick={pasteUrl}>
					{<LinkOutlined />}
				</Button>
				<Input
					className="input-box"
					placeholder="Paste URL here"
					allowClear
					style={{
						width: "80%",
						textAlign: "left",
					}}
					onPressEnter={submitUrl}
					onChange={handleChange}
					value={input}
				/>
				<Button className="button-green" onClick={submitUrl}>
					Submit
				</Button>
			</Input.Group>
		</div>
	);
};

export default InputUrl;
